import { defineStore } from "pinia";
import expApi from "@/api/exp.api";
import userApi from "@/api/user.api";
import { isEmpty } from "lodash";
const useUserStore = defineStore("user", {
  persist: {
    enabled: true,
    strategies: [
      {
        key: "userstate",
        storage: localStorage,
      },
    ],
  },
  state: () => ({
    user: {},
    exp: {},
    round: {},
    curExpId: "",
    tea: {},
  }),
  actions: {
    setCurExpId(val) {
      this.curExpId = val;
    },

    setUser(data) {
      if (data.expid) {
        let regexp = /\[(.+?)\]/g;
        const arr = [...data.expid.matchAll(regexp)];
        data.expid = arr.map(item => item[1]);
        this.curExpId = data.expid[0];
      }
      data?.term?.config && typeof data?.term?.config === "string" && (data.term.config = JSON.parse(data.term.config));

      this.user = JSON.parse(JSON.stringify(data));
    },

    // 获取实验
    async fetchExp({ immediate = false } = {}) {
      const round = {};
      const exp = {};
      if (!isEmpty(this.exp) && !immediate) return;
      const res = await expApi.getExp({
        termid: this.user.term.id,
      });
      res.result.forEach(item => {
        round[item.round] ? round[item.round].push(item) : (round[item.round] = [item]);
        exp[item.id] = item;
      });
      this.round = round;
      this.exp = exp;
    },

    async addRound(keys) {
      this.round = {
        ...this.round,
        [keys]: [{ chooseTimes: 0, courseTimes: 0 }],
      };
    },

    async fetchTea() {
      const tea = {};
      const res = await userApi.getUser({
        identity: "tea",
        termid: this.user.term.id,
      });
      res.result.list.forEach(item => {
        tea[item.id] = item;
      });
      this.tea = tea;
    },
  },
});

export default useUserStore;
