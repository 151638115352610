import Storages from "js-storage";

let storage = Storages.localStorage;

const TOKEN = "token";
const USER = "user";
const CURRENTEXP = "currentExp";
const COURSE = "COURSE";
const EXTRA = "extra";
const NOTICE = "notice";
const LOGINDATA = "LOGINDATA";

function removeAll() {
  return storage.remove([TOKEN, USER, CURRENTEXP, COURSE, EXTRA]);
}

// 生成全部数据的预留时间
function setStatisTime(time) {
  return storage.set("statis", time);
}

function getStatisTime() {
  return storage.get("statis");
}

function getToken() {
  return storage.get(TOKEN);
}
function setToken(token) {
  return storage.set(TOKEN, token);
}

function setUser(data) {
  return storage.set(USER, data);
}

function getUser() {
  return storage.get(USER);
}

function setCurrentExp(data) {
  return storage.set(CURRENTEXP, data);
}

function getCurrentExp() {
  return storage.get(CURRENTEXP);
}

function setCourse(data) {
  return storage.set(COURSE, data);
}

function getCourse() {
  return storage.get(COURSE);
}

function setExtraData(data) {
  let originData = getExtraData() || {};
  return storage.set(EXTRA, Object.assign(originData, data));
}
function getExtraData() {
  return storage.get(EXTRA);
}

function setNotice(data) {
  return storage.set(NOTICE, data);
}
function getNotice() {
  return storage.get(NOTICE);
}

function setUserLoginData(data) {
  return storage.set(LOGINDATA, data);
}

function getUserLoginData() {
  return storage.get(LOGINDATA);
}

export {
  getToken,
  setToken,
  getUser,
  setUser,
  setCurrentExp,
  getCurrentExp,
  setCourse,
  getCourse,
  removeAll,
  setExtraData,
  getExtraData,
  setNotice,
  getNotice,
  setUserLoginData,
  getUserLoginData,
  setStatisTime,
  getStatisTime,
};
