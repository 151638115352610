import Qs from "qs";
import CryptoJS from "crypto-js";
// generateReqKey ：用于根据当前请求的信息，生成请求 Key；
function generateReqKey(config) {
  // 响应的时候，response.config 中的data 是一个JSON字符串，所以需要转换一下
  if (config && config.data && isJsonStr(config.data)) {
    config.data = JSON.parse(config.data);
  }
  const { method, url, params, data } = config; // 请求方式，参数，请求地址，
  return [method, url, Qs.stringify(params), Qs.stringify(data)].join("&"); // 拼接
}

// 判断一个字符串是否为JSON字符串
let isJsonStr = str => {
  if (typeof str == "string") {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("error：" + str + "!!!" + e);
      return false;
    }
  }
};

/*生成符合规范长度的密钥*/
const genkey = (secret, length = 24) => {
  let hash = CryptoJS.algo.SHA256.create();
  hash.update(secret);
  let result = hash.finalize();
  // WordArray object
  return result.toString(CryptoJS.enc.Hex).substr(0, length);
};

//加密方法
function encryptByAes256(data, key = "wlsy", iv = "fbhejv") {
  key = CryptoJS.enc.Utf8.parse(genkey(key));
  iv = CryptoJS.enc.Utf8.parse(genkey(iv, 16));
  let encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return encrypted.ciphertext.toString().toLowerCase();
}

function convertSectionToTxt(section) {
  let sections = ["", "第一二节", "第三四节", "第五六节", "第七八节", "第九十节", "最后两节"];
  return sections[section];
}

function convertSectionToTime(section) {
  let sections = ["", "08:20", "09:55", "14:00", "15:55", "18:30", "20:10"];
  return sections[section];
}

function convertWkToName(wk) {
  let wkObj = {
    monday: "周一",
    tuesday: "周二",
    wednesday: "周三",
    thursday: "周四",
    friday: "周五",
    saturday: "周六",
    sunday: "周日",
  };
  return wkObj[wk];
}

function convertName(week, wk, section) {
  let wkObj = {
    monday: "星期一",
    tuesday: "星期二",
    wednesday: "星期三",
    thursday: "星期四",
    friday: "星期五",
    saturday: "星期六",
    sunday: "星期日",
  };
  let sectionObj = ["", "第一二节", "第三四节", "第五六节", "第七八节", "第九十节", "最后两节"];
  return "第" + week + "周" + wkObj[wk] + sectionObj[section];
}
function convertWkTo(wk) {
  let wks = ["", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  return wks[wk];
}

function generateParams(obj) {
  return Object.entries(obj)
    .map(item => {
      return item.join("=");
    })
    .join("&");
}
function generateURL(url, params) {
  return `${process.env.VUE_APP_API_URL}${url}?${generateParams(params)}`;
}

export {
  encryptByAes256,
  isJsonStr,
  generateReqKey,
  convertSectionToTxt,
  convertSectionToTime,
  convertName,
  convertWkTo,
  generateURL,
  convertWkToName,
};
