import request from "@/http/request";

// auth模块接口， 即为主模块的登录、注册、忘记密码接口

export function login(data) {
  return request.post("/login", data);
}

export function reset(data) {
  return request.post("/resetpwd", data);
}

export function addLog(data) {
  return request.post("/log/add", data);
}

export function getLog(data) {
  return request.post("/log/get", data);
}

export function chat(data) {
  return request.post("/wx/msg/send", data);
}

export function registerAdmin(data) {
  return request.post("/addAdmin", data);
}

export function statis(data) {
  return request.post("/statis", data);
}
