<template>
  <div class="login-layout">
    <LoginFormCom ref="login" type="登录" :form-data="formData" @submitForm="login"></LoginFormCom>
  </div>
</template>

<script>
import LoginFormCom from "./components/LoginFormCom.vue";
import { login } from "@/api/auth.api";
import { encryptByAes256 } from "@/utils/util";
import { setToken, removeAll, setUserLoginData, getUserLoginData } from "@/utils/storage";
import TermApi from "@/api/term.api";
import useUserStore from "@/store/userStore";

export default {
  name: "LoginView",
  components: {
    LoginFormCom,
  },
  data() {
    return {
      formData: {
        termid: {
          placeholder: "请选择学期",
          type: "select",
          rules: [{ required: true, message: "请选择学期", trigger: "blur" }],
          list: [],
        },
        username: {
          placeholder: "请输入学号或工号",
          type: "input",
          rules: [{ required: true, message: "请输入学号或工号", trigger: "blur" }],
        },
        password: {
          placeholder: "请输入密码",
          type: "input",
          pwd: true,
          rules: [{ required: true, message: "请输入密码", trigger: "blur" }],
        },
      },
      buttons: [{ txt: "忘记密码", href: "/forget" }],
    };
  },
  created() {
    removeAll();
    this.getTerms();
  },
  mounted() {
    this.$refs.login.updateForm(getUserLoginData());
  },
  methods: {
    // 获取这个学校的所有学期
    async getTerms() {
      const res = await TermApi.getTerm();
      this.formData.termid.list.push(...res.result);
    },

    async login(data) {
      try {
        let res = await login({
          username: data.username,
          password: encryptByAes256(data.password).substr(0, 15),
          termid: data.termid,
        });
        setUserLoginData(data);
        useUserStore().setUser(res.result.data);
        setToken(res.result.token);
        await this.$router.push("/work/list");
        // this.$addLog({
        //   type: "用户登录",
        // });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
