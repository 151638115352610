import request from "@/http/request";

export default {
  getTerm(data = {}) {
    return request.post("/term/get", data);
  },

  updateTerm(data = {}) {
    return request.post("/term/update", data);
  },
};
