import request from "@/http/request";

export default {
  getExp(data) {
    return request.post("/exp/get", data);
  },
  updateExp(data) {
    return request.post("/exp/update", data);
  },
};

// export function addOrUpdateExp(data) {
//   return request.post("/exp/save", data);
// }

// export function getExp(data) {
//   return request.post("/exp/get", data);
// }

// export function addExpTea(data) {
//   return request.post("/exp/tea/add", data);
// }

// export function deleteExpTea(data) {
//   return request.post("/exp/tea/delete", data);
// }

// export function getExpTea(data) {
//   return request.post("/exp/tea/get", data);
// }

// export function getExpTeaByExp(data) {
//   return request.post("/exp/tea/getbyexp", data);
// }

// export function addExpFile(data) {
//   return request.post("/exp/file/add", data);
// }
// export function delExpFile(data) {
//   return request.post("/exp/file/del", data);
// }
// export function getExpFile(data) {
//   return request.post("/exp/file/get", data);
// }

// export function statisticExp(data) {
//   return request.post("/exp/statistic", data);
// }

// export function statisticExpTea(data) {
//   return request.post("/exp/tea/statistic", data);
// }

// export function updateDead(data) {
//   return request.post("/exp/tea/updatedead", data);
// }
