import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/Login/LoginView.vue";
import LoginAdminView from "@/views/Login/LoginAdminView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  // {
  //   path: "/adfjsaio/fauilfb",
  //   component: () => import("@/views/auth/addAdmin.vue"),
  // },

  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },

  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true },
  {
    path: "/login/admin",
    name: "loginAdmin",
    component: LoginAdminView,
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "",
    component: () => import("@/layout/MenuLayout"),
    children: [
      {
        path: "/main",
        component: () => import("@/views/Main/MainView.vue"),
      },
      {
        path: "/info/term/config",
        component: () => import("@/views/Term/TermConfigView.vue"),
      },
      {
        path: "/info/exp/round",
        component: () => import("@/views/Exp/ExpRoundView.vue"),
      },
      {
        path: "/info/user/tea",
        component: () => import("@/views/User/TeaImportView.vue"),
      },
      {
        path: "/info/user/stu",
        component: () => import("@/views/User/StuImportView.vue"),
      },
      {
        path: "/info/class",
        component: () => import("@/views/Classs/ClassImportView.vue"),
      },
      {
        path: "/info/class/exp",
        component: () => import("@/views/Classs/ClassAllocationView.vue"),
      },
      {
        path: "/class/info",
        component: () => import("@/views/Classs/ClassInfoView.vue"),
      },
      {
        path: "/class/stu/:college/:classes",
        name: "classstu",
        component: () => import("@/views/Classs/ClassStuView.vue"),
      },
      {
        path: "/exp/info",
        component: () => import("@/views/Exp/ExpInfoView.vue"),
      },
      {
        path: "/course/table",
        component: () => import("@/views/Course/CourseTableView"),
      },
      {
        path: "/work/list",
        component: () => import("@/views/work/WorkListView.vue"),
      },
      {
        path: "/work/detail",
        name: "workDetail",
        component: () => import("@/views/work/WorkDetailView.vue"),
      },
      {
        path: "/doc",
        component: () => import("@/views/Exp/DocView.vue"),
      },
    ],
  },
  {
    path: "/work/correct",
    name: "workCorrect",
    component: () => import("@/views/work/WorkCorrectView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

export default router;
