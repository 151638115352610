<template>
  <div class="login-layout">
    <LoginFormCom type="登录" :form-data="formData" @submitForm="login"></LoginFormCom>
  </div>
</template>

<script>
import LoginFormCom from "./components/LoginFormCom.vue";
import { login } from "@/api/auth.api";
import { encryptByAes256 } from "@/utils/util";
import { setToken } from "@/utils/storage";
import useUserStore from "@/store/userStore";
import { mapState } from "pinia";

export default {
  name: "LoginAdminView",
  components: {
    LoginFormCom,
  },
  computed: {
    ...mapState(useUserStore, ["user"]),
  },
  data() {
    return {
      formData: {
        username: {
          placeholder: "请输入学号或工号",
          type: "input",
          rules: [{ required: true, message: "请输入学号或工号", trigger: "blur" }],
        },
        password: {
          placeholder: "请输入密码",
          type: "input",
          pwd: true,
          rules: [{ required: true, message: "请输入密码", trigger: "blur" }],
        },
      },
    };
  },
  methods: {
    async login(data) {
      let res = await login({
        username: data.username,
        password: encryptByAes256(data.password).substr(0, 15),
        identity: "admin",
      });
      setToken(res.result.token);
      useUserStore().setUser(res.result.data);
      // this.$addLog({
      //   type: "用户登录",
      // });
      await this.$router.push("/main");
    },
  },
};
</script>
