import request from "@/http/request";

function resetPwd(data) {
  return request.post("/user/reset", data);
}

function updateUser(data) {
  return request.post("/user/update", data);
}

function createUser(data) {
  return request.post("/user/create", data);
}

function getUser(data) {
  return request.post("/user/get", data);
}

function getStuExpDetail(data) {
  return request.post("/user/stu/getExpDetail", data);
}

export default { createUser, updateUser, resetPwd, getUser, getStuExpDetail };
