<template>
  <div>
    <img src="../../../assets/biaoti_img@2x.png" width="385px" height="50px" alt="标题" />
    <div class="login-box">
      <el-form ref="loginForm" :model="form" :rules="rules" status-icon>
        <el-form-item v-for="(item, key) in data" :key="key" :prop="key">
          <el-input
            v-if="item.type === 'input'"
            v-model="form[key]"
            :placeholder="item.placeholder"
            autocomplete="on"
            :show-password="item.pwd"
            :value="form[key]"
          ></el-input>

          <Select v-if="item.type === 'select'" v-model="form[key]" style="width: 100%">
            <Option v-for="li in item.list" :value="li.id" :key="li.id">{{ li.name }}</Option>
          </Select>
        </el-form-item>

        <el-form-item>
          <el-button @click="submitForm('loginForm')" type="primary" style="width: 100%">{{ type }} </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginFormCom",
  data() {
    return {
      form: {},
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rules: {
      get() {
        let res = {};
        for (let key in this.formData) {
          if (this.formData[key].rules) {
            res[key] = this.formData[key].rules;
          }
        }
        return res;
      },
    },
    txtBtn: {
      get() {
        return this.buttons;
      },
    },
    data: {
      get() {
        return this.formData;
      },
    },
  },
  created() {
    this.form = this.getFormData();
  },

  methods: {
    getFormData() {
      let res = {};
      Object.keys(this.formData).forEach(key => {
        res[key] = "";
      });
      return res;
    },

    updateForm(data) {
      Object.assign(this.form, data);
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false;
        }
        this.$emit("submitForm", this.form);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.login-box {
  width: 400px;
  padding: 50px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgb(19 26 92 / 6%), 0 6px 30px rgb(19 26 92 / 12%);
  border-radius: 4px;
}
</style>
