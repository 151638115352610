import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersit from "pinia-plugin-persist";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import WJYUI from "wjygrit-ui";
import "wjygrit-ui/lib/styles/index.css";

import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";

import VXETable from "vxe-table";
import "vxe-table/lib/style.css";

import VCalendar from "v-calendar";

import "./styles/index.scss";

import VueFullscreen from "vue-fullscreen";
import { addLog } from "@/api/auth.api";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

// import { io } from "socket.io-client";
// const socket = io("http://localhost:12939");

Vue.config.productionTip = false;
// Vue.prototype.$socket = socket;
Vue.prototype.$addLog = addLog;

const pinia = createPinia().use(piniaPluginPersit);

Vue.use(PiniaVuePlugin);
Vue.use(ElementUI);
Vue.use(WJYUI);
Vue.use(ViewUI);
Vue.use(VXETable);
Vue.use(VueFullscreen);
Vue.use(VCalendar, {
  screens: {
    calendar2: "1160px",
    calendar3: "1410px",
  },
});

new Vue({
  router,
  pinia,
  render: h => h(App),
}).$mount("#app");
